import React from "react";

const User = ({ name, location, email }) => {
  const { first, last } = name;
  const { city, state } = location;
  return (
    <div className="card" style={{width: "18rem"}}>
      <div className="card-body">
        <h5 className="card-title"><strong>Name:</strong> {first} {last}</h5>
        <h6 className="card-subtitle mb-2 text-muted"><strong>City:</strong> {city}</h6>
        <p><strong className="card-text"></strong> {state}</p>
        <a href="http://www.google.com" className="card-link"><strong>Email:</strong> {email}</a>
      </div>
    </div>
  );
};

export default User;
