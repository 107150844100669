import React, { useState, useEffect } from 'react';
import axios from 'axios';
import User from './components/user';

function App() {

  const [users, setUsers] = useState([]);



  useEffect(() => {
    (async () => {
      const users = await axios({
        method: "get",
        url: "https://randomuser.me/api/?page=1&results=10&nat=us"
      }).catch(function (error) {
        console.log("[GP] : " + error);
      });
      setUsers(users.data.results);
    })();
  }, []);

  return (
    <div>
      <h1>Users List</h1>
      <div>
        {users.map(user => (
          <User key={user.login.uuid} {...user} />
        ))}
      </div>
    </div>
  );
}

export default App;
